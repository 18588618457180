<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <v-expansion-panels flat accordion>
        <v-alert type="info" class="ma-5" v-if="!getOrders || getOrders.length === 0">
          No active orders
        </v-alert>
        <v-expansion-panel
          v-for="(order, i) in getOrders"
          :key="i"
          :class="`${i % 2 === 0 ? 'white' : 'grey lighten-5'}`"
        >
          <v-expansion-panel-header color="d-inline-flex justify-space-between">
            <!-- <v-icon style="max-width: 40px;" class="px-1">mdi-basket</v-icon> -->
            <div>
              <h4>{{ order.customerName }}</h4>
              <span class="body-2">{{ order.timeString }}</span>
              <p>{{ getAddressString(order) }}</p>
              <!-- <p>{{ formatItemsString(order.items) }}</p> -->
            </div>
            <div class="d-flex justify-end mx-2">
              <v-btn @click="$router.push({ name: 'delivery.order', params: { uuid: order.uuid, back: { name: $route.name } } })" :color="getStatusTypeColor(order.statusName)" depressed>
                {{ order.statusName }}2
              </v-btn>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0 ma-0">
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-start">
                <v-btn
                  outlined
                  color="secondary"
                  depressed
                  style="min-height: 50px"
                  class="px-1 ma-1"
                >
                  <v-icon small>mdi-basket</v-icon>
                  <span class="ml-1">
                    {{ `${order.currency}. ${order.orderPrice.toLocaleString()}` }}...
                  </span>
                </v-btn>
                <!-- :dark="isDarkStatusTheme(getActiveStatus(order.tracks))" -->
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  :disabled="order.statusName === 'LOADING'"
                  dark
                  depressed
                  @click="setStatus(order, 'DELIVERY')"
                  style="min-height: 50px"
                  class="px-1 ma-1"
                  color="blue darken-2"
                >
                  SELECT
                </v-btn>
                <v-btn
                  :disabled="order.statusName === 'LOADING'"
                  dark
                  depressed
                  @click="setStatus(order, 'NEAR')"
                  style="min-height: 50px"
                  class="px-1 ma-1 black--text"
                  color="green accent-3"
                >
                  NEAR
                </v-btn>
                <v-btn
                  :disabled="order.statusName === 'LOADING'"
                  dark
                  depressed
                  @click="setStatus(order, 'DELIVERED')"
                  style="min-height: 50px"
                  class="px-1 ma-1"
                  color="green"
                >
                  DELIVERED
                </v-btn>
              </div>
            </div>
            <markers-map
              :markers="getMarkers(order)"
              :geofence="getAosCoordinates"
              style="min-height: 350px; height: 350px;"
              show-current-button
            />
            <v-btn
              dark
              depressed
              @click="$copy(order.customerPhone)"
              style="min-height: 50px; position: absolute; margin-top: -60px"
              class="px-1 mx-2"
            >
              <v-icon small>mdi-content-copy</v-icon>
              {{ order.customerPhone }}
            </v-btn>
          </v-expansion-panel-content>
          <v-progress-linear
            :color="order.statusColor"
            buffer-value="0"
            stream
            v-if="isActiveOrder(order.activeStatus)"
          ></v-progress-linear>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import mixin from "../orders/orders.mixin";
import ViewContainer from "@/common/layouts/ViewContainer";
import MarkersMap from "@/common/components/MarkersMap";
import {
  STATUS_LOADING,
  STATUS_DISPATCHED,
  STATUS_DELIVERY,
  STATUS_NEAR,
  STATUS_DELIVERED,
} from "@/store/actions/type.orders";

export default {
  name: "ActiveDelivery",
  mixins: [mixin],
  components: { ViewContainer, MarkersMap },
  mounted() {
    this.loadDriverActiveOrders();
  },
  // watch: {
  //   getDriverActiveOrders(n) {
  //     if(this.notEmptyArray(n)) {
  //       this.$watchPosition();
  //     }
  //   }
  // },
  computed: {
    ...mapGetters("orders", ["getDriverActiveOrders"]),

    getOrders() {
      const orders = this.getDriverActiveOrders;
      if (this.notEmptyArray(orders)) {
        return orders.map((o) => {
          const activeStatus = this.getActiveStatus(o.tracks);
          const statusColor = this.getStatusTypeColor(activeStatus);
          const timeString = this.formatTime(o.time);
          return { ...o, activeStatus, statusColor, timeString };
        });
      } else {
        return [];
      }
    },
    getAosCoordinates() {
      const { activeAddressId: a } = this.$getUserInfo();
      if (!a || !a.store) {
        return [];
      }
      return a.store.aosGeom.geometry.coordinates;
    },
  },
  methods: {
    ...mapActions("orders", ["loadDriverActiveOrders", "updateOrderStatus"]),

    getMarkers(order) {
      const a = this.getAddress(order);
      const target = {
        icon: "mdi-home-map-marker",
        color: "white",
        status: order.customerName,
        class: this.getStatusTypeColor(order.statusName) + " pin",
        latitude: a.latitude || 12.7533427,
        longitude: a.longitude || 44.8926315,
        target: true,
        type: "CUSTOMER",
        click: () => {},
      };

      const driver = {
        // icon: "mdi-moped",
        icon: "mdi-truck-fast-outline",
        color: "black",
        class: "amber circle",
        status: order.statusName,
        latitude: order.latitude || 12.7543,
        longitude: order.longitude || 44.8955,
        type: "DRIVER",
        click: () => {},
      };

      const markers = [
        {
          icon: "mdi-headset",
          color: "#195567",
          class: "secondary pin2",
          status: "MAKZANI",
          latitude: 12.75896,
          longitude: 44.89213,
          type: "STORE",
          click: () => {},
        },
        { ...target },
        { ...driver },
      ];

      return markers;
    },
    setStatus(order, status) {
      const params = {
        orderUuid: order.uuid,
        driverUuid: this.$getUserInfo().uuid,
        status,
      };
      this.updateOrderStatus(params);
    },

    getAddress(order) {
      if (!order || !order.addressId || !this.notEmptyArray(order.customerAddresses)) {
        return "";
      }
      const { addressId, customerAddresses } = order;
      return customerAddresses.find((f) => f.id === addressId);
    },
    getAddressString(order) {
      const a = this.getAddress(order);
      return `${a.title} / ${a.buildingNumber} ${a.street}, ${a.area} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
    callPhone(number) {
      this.$call(number);
    },
    formatTime(t) {
      return moment(t).format("ddd | DD/MM/YYYY hh:mm A");
    },
    formatItemsString(items) {
      if (!this.notEmptyArray(items)) {
        return "";
      }

      let result = [];
      for (let i in items) {
        if (items[i].product) {
          result.push(`${items[i].product.name} x ${items[i].quantity}`);
        }
      }
      return result.join(" | ");
    },
    getActiveStatus(tracks) {
      const track = [...tracks].sort((a, b) => new Date(a.time) - new Date(b.time)).pop();
      return !!track ? track.status : null;
    },
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
